import Styled from './Header.styled';
import Logo from '../../components/Logo/Logo';
import { NAMESPACE, ROUTES } from '@models/enums';
import { useTranslation } from 'next-i18next';
import { useAuthentication } from '@/lib/auth';
import { MobileStepIndicator } from '@components/StepIndicator';
import { useResponsive } from 'hooks/responsive';

interface HeaderProps {
  hideStepIndicator?: boolean;
}

const Header = ({ hideStepIndicator }: HeaderProps) => {
  const { t } = useTranslation(NAMESPACE.DEFAULT);
  const { isAuthenticated } = useAuthentication();
  const { isTabletOrMobile } = useResponsive();

  return (
    <Styled.Header>
      <Styled.HeaderWrapper>
        <Logo />
        {!isAuthenticated ? (
          <Styled.Link data-testid="login-cta" href={`/${ROUTES.LOGIN}`}>
            {t('LOGIN_BUTTON')}
          </Styled.Link>
        ) : (
          <Styled.Link data-testid="logout-cta" href={`/${ROUTES.LOGOUT_CALLBACK}`}>
            {t('LOGOUT_BUTTON')}
          </Styled.Link>
        )}
      </Styled.HeaderWrapper>
      {!hideStepIndicator && isTabletOrMobile && (
        <Styled.StepIndicatorWrapper>
          <MobileStepIndicator />
        </Styled.StepIndicatorWrapper>
      )}
    </Styled.Header>
  );
};

export default Header;
