import Link from 'next/link';
import styled from 'styled-components';

export const StyledHeader = {
  Header: styled.header`
    position: fixed;
    height: ${({ theme }) => theme.pineNavHeight};
    max-width: ${({ theme }) => theme.pineLayoutMaxWidth};
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100vw;
    background: ${({ theme }) => theme.background.primary};
    z-index: 100;
    margin: 0 auto;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      height: ${({ theme }) => theme.pineNavHeightMobile};
    }
  `,
  HeaderWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 16px;
  `,
  Link: styled(Link)`
    all: unset;

    font-family: var(--font-futura);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    padding: 6px 16px;
    border-radius: 24px;
    min-width: 60px;
    text-align: center;
    background: ${({ theme }) => theme.cta.secondary.default.background};
    color: ${({ theme }) => theme.cta.secondary.default.color};
    border: 1px solid ${({ theme }) => theme.cta.secondary.default.border};
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
  `,
  StepIndicatorWrapper: styled.div`
    position: fixed;
    top: ${({ theme }) => theme.pineNavHeight};
    background-color: ${({ theme }) => theme.background.primary};
    width: 100vw;
  `,
};

export default StyledHeader;
