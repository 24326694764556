import Styled from './ShortFlowLayout.styled';
import { ShortAppProvider } from 'contexts/short-app.context';
import { IndicatorContextProvider, StepIndicator } from '@components/StepIndicator';
import { useResponsive } from 'hooks/responsive';
import { ReactNode } from 'react';
import Header from './components/Header';
import Footer from '../components/Footer/Footer';

interface FlowLayoutProps {
  children: ReactNode;
  hideStepIndicator?: boolean;
}

const ShortFLowFlowLayout = ({ children, hideStepIndicator }: FlowLayoutProps) => {
  const { isDesktopOrLaptop } = useResponsive();

  return (
    <ShortAppProvider>
      <IndicatorContextProvider steps={[]}>
        <Styled.Wrapper>
          <Header />
          <Styled.Section>
            <Styled.Content>{children}</Styled.Content>
            {!hideStepIndicator && isDesktopOrLaptop && (
              <Styled.StepIndicatorWrapper>
                <StepIndicator />
              </Styled.StepIndicatorWrapper>
            )}
          </Styled.Section>
          <Footer />
        </Styled.Wrapper>
      </IndicatorContextProvider>
    </ShortAppProvider>
  );
};

export default ShortFLowFlowLayout;
